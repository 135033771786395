<template>
  <div class="header-user">
    <client-only>
      <div class="user-block" @mouseleave="mouseLeave" @mouseover="mouseOver">
        <div class="user-info">
          <div class="username">{{ truncatedFullName }}</div>
          <div class="balance">{{ $formatNumberWithCommas(store.currentUser?.balance, ',') }} K</div>
        </div>
        <div class="user-menu" :class="{ active: isShowMenu }">
          <div class="gr-avatar">
            <div class="avatar">
              <BaseImg
                :src="`/assets/images/components/desktop/avatar/${store.currentUser?.avatar}.webp`"
                alt="avatar"
              />
            </div>
            <BaseImg
              class="avatar-dropdown"
              :src="
                isShowMenu
                  ? '/assets/images/components/desktop/menu/avatar-down-active.svg'
                  : `/assets/images/components/desktop/menu/avatar-dropdown.svg`
              "
              alt="avatar-dropdown"
            />
          </div>
          <div class="menu-dropdown">
            <div
              v-for="(item, index) in USER_MENU"
              :id="item?.name"
              :key="index"
              class="menu-item"
              :class="{ active: item.activeUrl === route.fullPath }"
              @click="onClick(item)"
            >
              <BaseImg class="icon icon-inactive" :src="item?.icon?.inactive" alt="icon inactive" />
              <BaseImg class="icon icon-active" :src="item?.icon?.active" alt="icon active" />
              <div class="text">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </client-only>
    <BaseButton id="withdraw-btn-header-pc" class="base-button--btn-secondary" @click="onNavWithdraw()">
      <BaseImg src="/assets/images/components/desktop/icon-withdraw.svg" class="icon" alt="icon plus" />
      Rút Tiền
    </BaseButton>
    <BaseButton id="deposit-btn-header-pc" class="base-button--btn-primary" @click="onNavDeposit()">
      <BaseImg src="/assets/images/components/desktop/icon-deposit.svg" class="icon" alt="icon plus" />
      Nạp Tiền
    </BaseButton>
  </div>
</template>
<script setup lang="ts">
import BaseButton from '~/components/common/base-button.vue'
import { useLogout } from '~/composables/user/useLogout'
import { USER_MENU } from '~/constants/menu'
import { useAppStore } from '~/store/app'
import { ACCOUNT_URLS } from '~/config/page-url'
import { IMenuUserDesktop } from '~/types/menu.type'
const { $pinia, $formatNumberWithCommas } = useNuxtApp()
const store = useAppStore($pinia)
const { logout } = useLogout()
const isShowMenu = ref(false)
const route = useRoute()

const truncatedFullName = computed(() => {
  const fullname = store.currentUser?.fullname || ''
  return fullname.length > 15 ? fullname.substring(0, 12) + '...' : fullname
})
const onClick = (item: IMenuUserDesktop) => {
  isShowMenu.value = false
  if (item.url === ACCOUNT_URLS.DEPOSIT_DA) {
    onNavDeposit()
    return
  }
  if (item.url === ACCOUNT_URLS.WITHDRAW_BANK) {
    onNavWithdraw()
    return
  }
  if (item.isLogout) {
    logout()
  } else {
    useRoute().path !== item.url && navigateTo(item.url)
  }
}

const mouseOver = () => {
  isShowMenu.value = true
}

const mouseLeave = () => {
  isShowMenu.value = false
}
const onNavDeposit = () => {
  if (useRoute().path !== ACCOUNT_URLS.DEPOSIT_DA) {
    navigateTo(ACCOUNT_URLS.DEPOSIT_DA)
  }
}
const onNavWithdraw = () => {
  if (useRoute().path === ACCOUNT_URLS.WITHDRAW_BANK) {
    return
  }
  navigateTo(ACCOUNT_URLS.WITHDRAW_BANK)
}
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/header/user-logged.scss" />
